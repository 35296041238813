
@mixin spin($prefix, $width: 5rem, $spinTime: 500ms, $zoomTime: 750ms) {
  $spinName: #{$prefix}Spin;
  $zoomName: #{$prefix}ZoomInOut;

  text-align: center;
  overflow: hidden;
  animation: #{$spinName} $spinTime infinite linear, #{$zoomName} $zoomTime 1 ease-in-out;
  @keyframes #{$spinName} {
    from {
      transform: rotate(360deg);
    } to {
      transform: rotate(0deg);
    }
  }
  @keyframes #{$zoomName} {
    from {
      width: .1rem;
    } to {
      width: $width;
    }
  }
}

@mixin customCheckbox($borderColor, $backgroundColor, $content: '') {
  cursor: pointer;
  appearance: none;
  background-color: $white;
  border: 1px solid $borderColor;
  margin-top: .5em;
  border-radius: 0;

  &.checked,
  &:checked {
    @if $content != '' {
      color: $black;
      &:after {
        content: "#{$content}";
        margin-left: 0.225rem;
        top: 0 !important;
      }
    } @else {
      background-image: none;
      background-color: $backgroundColor;
      box-shadow: inset 0 0 0 1px $white;
    }
  }
}

// Hack to make it look okay for people that resize their browsers into a square. This is <1% use case.
@mixin media-breakpoint-special-snowflake-xs() {
  @media (min-width: 476px) and (max-width: 575px) {
    @content;
  }
}

@mixin media-breakpoint-up-from($min-width) {
  @media (min-width: $min-width) {
    @content;
  }
}

@mixin elevation-1() {
  box-shadow: 0 0 5px 0 rgba(45, 0, 53, 0.25);
}

@mixin elevation-2() {
  box-shadow: 0 0 2px 0 rgba(45, 0, 53, 0.25), 0 0 8px 0 rgba(45, 0, 53, 0.25);
}

@mixin elevation-3() {
  box-shadow: 0 1px 4px 0 rgba(45, 0, 53, 0.30), 0 1px 7px 1px rgba(45, 0, 53, 0.15);
}

@mixin elevation-4() {
  box-shadow: 0 1px 4px 1px rgba(45, 0, 53, 0.30), 0 1px 8px 2px rgba(45, 0, 53, 0.15);
}

@mixin branded-font() {
  font-family: var(--brand-primary-font, sans-serif);
}
