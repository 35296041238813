// NOTE: This file is included glabally in the angular.json "styles"
// Import the common/shared variables and mixins
// variables needs imported before bootstrap since it contains variables that are referenced by bootstrap
@import './scss/variables.scss';
@import './scss/mixins';

// 3rd party ScSS imports (use with caution to follow branding)
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'ngx-toastr/toastr';
@import '@ng-select/ng-select/scss/default.theme';
@import 'primeng/resources/themes/bootstrap4-light-blue/theme.css';
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,900");

// Variables that will change between brands should be defined in the appropriate brand-variables file
// The following code creates a version of css-var-mapping specific to each brand variables
// which is then used by theme service to map global css vars when the brand changes.
@import './scss/highlights/highlights-variables';
@import './scss/css-var-mapping';

// The default brand is the ZB theme (must last in the imports to provide SCSS vars)
@import './scss/zbportal/zb-variables';
@import './scss/css-var-mapping';

@import './scss/zbportal/zb-redesign-variables';
@import './scss/css-var-mapping';

// Example code setting a CSS(run-time) variable within a ScSS(build-time) variable
// $brandTest: var(--brand-test);
// div {
//   background-color: $brandTest !important;
// }

// Example CSS selectors that utilize the brand class set by the theme service to
// specify brand specific styles
// .brand-highlights-theme {
//   // highlights specific style adjustments here
//   .btn-primary {
//     color: red;
//   }
// }

// .brand-zb {
//   // zb specific style adjustments here
//   .btn-primary {
//     color: blue;
//   }
// }

//========================================================================================
// GLOBAL STYLES
// NOTE: these should make use of the CSS var format var(--brand-<style>) and should NOT
// use ScSS variables.  CSS vars can change by brand whereas ScSS vars will not.

//TODO:  The following global styles needs to be cleaned up.  There are some styles
// that appear to be specific to products or components and should be moved into
// product specific stylesheets or moved into the component.

//TODO:  There are also several styles in the list below that override each other, the
// list should be evaluated to identify and consolidate those styles.

html,
body,
.container-fluid {
  height: 100%;
  font-weight: 300;
}

/* Responsive Font Overrides */
html {
  font-size: 10px;
  font-weight: 300;
  &:has(.brand-highlights-theme) {
    font-size: 0.75rem;
  }
  &:has(.brand-zb-redesign-theme) {
    font-size: 0.75rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 13px;
    &:has(.brand-highlights-theme) {
      font-size: 0.8rem;
    }
    &:has(.brand-zb-redesign-theme) {
      font-size: 0.8rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 14px;
    &:has(.brand-highlights-theme) {
      font-size: 0.9rem;
    }
    &:has(.brand-zb-redesign-theme) {
      font-size: 0.9rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  html {
    font-size: 19px;
    &:has(.brand-highlights-theme) {
      font-size: 1rem;
    }
    &:has(.brand-zb-redesign-theme) {
      font-size: 1rem;
    }
  }
}

/**
 * Global student style overrides.
 *
 * CSS selectors that are defined in components need to be added here unless that component has its encapsulation
 * set to None.
 */
body.student {
  .bgPrimary {
    background-color: student;
    color: $white;
  }

  .popover a,
  .leftMenuContainer a,
  a {
    color: #3478A0;
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: student;
  }

  .leftMenuContainer {
    .list-group-item-action {
      &:hover {
        background-color: $lightGray;
        color: $black;
      }

      &.active {
        background-color: student;
        border-color: student;
        color: $white;
      }
    }
  }
}

body.student {
  .sideMenu {
    .accordion-header {
      .btn {
        color: $student;
      }

      &.active,
      &:hover {
        background: $student;

        .btn {
          text-decoration: none;
          color: $white;
        }
      }

      &.active,
      &.bg-gradeless:hover {
        display: inline-block;
        background-color: $student;

        a {
          color: $white;
        }
      }
    }
  }
}

// Teacher's view requires 'auto' height
body.teacher.brand-highlights-theme {
  height: auto;
}

input,
textarea,
keygen,
select,
button {
  font-weight: 200;
  font-family: $font-family-base;
}

hr {
  color: $gray;
  background-color: $gray;
  height: 2px;
}

h1,
.h1 {
  font-size: 1.4rem;
}

h2,
.h2 {
  color: $gray;
  font-size: 1.3rem;
}

h3,
.h3 {
  font-size: 1.25rem;
}

h4,
.h4 {
  font-size: 1.2rem;
}

h5,
.h5 {
  font-size: 1.15rem;
}

h6,
.h6 {
  font-size: 1.1rem;
}

h1.styled-heading,
.h1.styled-heading,
.user-announcement-modal-body-render h1 {
  font-family: var(--brand-primary-font, sans-serif);
  font-size: 3.813rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  margin: 0;
}

h2.styled-heading,
.h2.styled-heading,
.user-announcement-modal-body-render h2 {
  font-family: var(--brand-primary-font, sans-serif);
  font-size: 3.063rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
}

h3.styled-heading,
.h3.styled-heading,
.user-announcement-modal-body-render h3 {
  font-family: var(--brand-primary-font, sans-serif);
  font-size: 2.438rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
}

h4.styled-heading,
.h4.styled-heading,
.user-announcement-modal-body-render h4 {
  font-family: var(--brand-primary-font, sans-serif);
  font-size: 1.938rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin:0;
}

h5.styled-heading,
.h5.styled-heading,
.user-announcement-modal-body-render h5 {
  font-family: var(--brand-primary-font, sans-serif);
  font-size: 1.563rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
}

h6.styled-heading,
.h6.styled-heading,
.user-announcement-modal-body-render h6 {
  font-family: var(--brand-primary-font, sans-serif);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
}

.primary-text {
  color: var(--brand-primary, $primary);
}

hr.styled-line,
.user-announcement-modal-body-render hr {
  height: 1px;
  background: $line;
}

a, .clickable {
  color: #b70000;
  cursor: pointer;
  text-decoration: none;
}

a {
  // styles for both default and red links
  &.text-link,
  &.red-text-link,
  .user-announcement-modal-body-render & {
    // "&" above is intentional, selecting an anchor tag inside of the announcement modal
    font-style: normal;
    font-weight: 700;
    text-decoration-line: underline;

    &:visited {
      color: $aprilViolet;
    }
  }

  // styles for default links
  &.text-link,
  .user-announcement-modal-body-render & {
    // "&" above is intentional, selecting an anchor tag inside of the announcement modal
    color: var(--brand-secondary, $secondary);

    &:hover {
      color: $blue;
    }
  }

  // styles for red links
  &.red-text-link {
    color: var(--brand-primary, $primary);

    &:hover {
      color: $primaryFade3;
    }
  }
}

button {
  // styles for both default and red links
  &.text-link,
  &.red-text-link {
    // "&" above is intentional, selecting an anchor tag inside of the announcement modal
    font-style: normal;
    font-weight: 700;
    text-decoration-line: underline;

    &:visited {
      color: $aprilViolet;
    }
  }

  // styles for default links
  &.text-link {
    // "&" above is intentional, selecting an anchor tag inside of the announcement modal
    color: var(--brand-secondary, $secondary);

    &:hover {
      color: $blue;
    }
  }

  // styles for red links
  &.red-text-link {
    color: var(--brand-primary, $primary);

    &:hover {
      color: $primaryFade3;
    }
  }
}

.brand-zb-redesign-theme, .brand-highlights-theme {
  a.disabled-link {
    pointer-events: none;
  }
}

nav a {
  // styles for active navigation links
  &.active-nav-link {
    border-bottom: 3px solid var(--brand-primary);
    font-weight: 700;
  }
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.bold {
  font-weight: bold;
}

.z-index-2 {
  z-index: 2;
}

.bg-white {
  background: $white;
}

.container-fluid {
  padding: 0;
}

.fullContentContainer {
  min-height: calc(100vh - 11.2rem);
}

.leftMenuContainer {
  padding-top: 0.8rem;
  border-right: 1px solid #c6c6c6;
  background: $white;
  min-height: 100%;
  padding-left: 0;
  padding-right: 0;

  @include media-breakpoint-up(md) {
    // Resets to bootstrap padding for col-4.
    padding-left: 15px;
    padding-right: 15px;
  }
}

.leftMenuContainer a,
.leftMenuContainer a:visited {
  color: $primary;
  font-weight: 300;
}

.leftMenuContainer {
  .list-group-item-action {
    &:hover {
      background-color: $lightGray;

      a:visited,
      a {
        color: $black;
      }
    }

    &.active {
      background-color: $primary;
      border-color: $primary;
      color: $white;

      &:visited,
      a {
        color: $white;
      }
    }
  }
}

.rightContentContainer {
  min-height: 100%;
  position: relative;
}

.diacritic {
  display: inline-block;
  width: .98em;
  text-indent: -999px;
  position: relative;
  vertical-align: baseline;
  overflow: hidden;
  background-size: 100% 100%;
  background-position: 0 0.2em;

  &.macron-oo {
    background-image: url(./assets/img/long-oo.svg);
    background-repeat: no-repeat;
  }

  &.breve-oo {
    background-image: url(./assets/img/breve-oo.svg);
    background-repeat: no-repeat;
  }
}

.wordBreakAll {
  word-break: break-all;
}

.buffer {
  padding: 10px;
  border-bottom: 1px solid #eceeef;
}

.close {
  opacity: 1;
  text-shadow: none;
}

.close:hover {
  opacity: .5;
}

.fa,
.fas {
  font-family: "Font Awesome 6 Free";
}

.bgPrimary {
  background-color: $primary;
  color: $white;
}

.bgTertiary {
  background-color: $tertiary;
  color: $white;
}

.tooltip-tertiary {
  &.show {
    opacity: 1;
  }

  .arrow {
    border-top-color: $tertiary;

    &:before {
      border-top-color: $tertiary;
    }
  }

  .tooltip-inner {
    background-color: $tertiary;
    color: $white;
  }
}

.popover-tertiary {
  max-width: 25rem;

  .popover-body {
    background-color: $tertiary;
    color: $white;
    padding: 1.25rem 1rem;
    box-shadow: .15rem .15rem 0 transparentize($black, .75);
  }

  &.bs-popover-top {

    .arrow:before,
    .arrow:after {
      border-top-color: $tertiary;
    }
  }

  &.bs-popover-right {

    .arrow:before,
    .arrow:after {
      border-right-color: $tertiary;
    }
  }
}

.w-33 {
  width: 33% !important;
}

.w-66 {
  width: 66% !important;
}

.defaultTransition {
  @include transition($btn-transition);
}

.spinImage {
  width: 5rem;
  margin-top: -5rem;
  @include spin('spinImage', 5rem);
}

.spinImageInitial {
  opacity: 1 !important;
  @include spin('initSpin', 5rem, 500ms, 250ms);
}

.form-check {
  .form-check-input {
    @include customCheckbox($tealDark, $teal);
  }
}

.mvBoli {
  font-family: "MV Boli";
}

.swirlBackgroundBottom {
  background-repeat: no-repeat;
  padding-bottom: 0rem;
}

.swirlBackgroundGray {
  background-color: $lightGray;
}

.swirlImage {
  background-image: url(./assets/img/zb_greyswirl.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 10rem;
  overflow: hidden;

  @include media-breakpoint-only(xs) {
    width: 100%;
    background-position: left -20rem bottom -13rem;
  }

  @include media-breakpoint-special-snowflake-xs() {
    background-position: left -30rem bottom -18rem;
  }

  @include media-breakpoint-up(sm) {
    background-position: left -15rem bottom -11rem;
  }

  @include media-breakpoint-up(md) {
    background-size: auto;
    background-position: left -58rem bottom -34rem;
  }

  @include media-breakpoint-up(lg) {
    background-position: left -53rem bottom -30rem;
  }

  @include media-breakpoint-up(xl) {
    background-position: left -30rem bottom -23rem;
  }
}

.studentBackground {
  background: linear-gradient(#99E3F1, #F9FEDA 70%);
}

.studentBackgroundIe {
  min-height: 79vh;
}

.underline {
  text-decoration: underline;
}

.bgBox {
  padding: .25rem .5rem;
  margin: .5rem 0;
}

.hideLongTitles {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 22rem;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
}

.dropdown-submenu > .dropdown-item {
  padding: 0;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.primaryContainer {
  min-height: calc(100vh - 11.2rem);
  position: relative;
}

.primaryContainer div {
  transition: left 0.4s;
}

#districtsSideMenu,
#schoolsSideMenu {
  overflow-y: scroll;
  height: 80%;

  .accordion-button {
    display: block !important;
  }
}

#productsSideMenu {
  overflow-y: auto;
  max-height: 100%;
}

.sideMenu {
  background-color: $white;
  position: absolute;
  left: -34%;
  width: 34%;
  padding: 0;
  transition: left 0.4s;
  z-index: 10;
  border-right: 1px solid #c6c6c6;
  border-bottom: 1px solid #c6c6c6;
}

.sideMenu {
  .accordion-header {
    @include transition($btn-transition);

    .sideMenuProductLine {
      font-size: 1.2rem;
      padding-left: 1rem;
    }
  }

  .leftMenuContainer a.list-group-item-action.active,
  .leftMenuContainer a:visited.list-group-item-action.active {
    color: $white;
  }

  .list-group .list-group-item {
    padding-left: 2.2rem;
  }

  .list-group a.list-group-item-action:hover {
    background-color: $lightGray;
  }

  .list-group-item-gradeless {
    padding: 0rem;
    background-color: transparent;
  }
}

.sideMenu {
  &.sideMenuVisible {
    left: 0;
  }
}

.startDateItem {
  font-size: 1.1rem;
  color: #b70000;

  &:hover {
    text-decoration: underline;
  }

  @include media-breakpoint-down(md) {
    font-size: 0.9rem;
  }
}

.fgRed {
  color: $red;
}

.classroom-activity-tool-tip {
  color: $tertiary;
}

// Works around Angular-Bootstrap incompatibility with ViewEncapsulation.
// Instead of making LessonStepsComponent ViewEncapsulation.None, only override the one style here.
.lessonStepListItem {
  .stepSubHeader {
    p {
      margin-bottom: 0;
    }
  }
}

// Works around Angular-Bootstrap incompatibility with ViewEncapsulation.
// Instead of making LessonStepsComponent ViewEncapsulation.None, only override the one style here.
.lessonStepListItem {
  .stepSubHeader {
    p {
      margin-bottom: 0;
    }
  }
}

.page-item {
  &.active .page-link {
    background-color: #ee0027;
  }
}

.error-handling-service-toast {
  background-image: url('./assets/img/toaster-error-icon.svg') !important;
  cursor: text !important;
}

.modal-body {
  overflow-y: auto;
  //TODO: This was not commented out in one of the styles originally
  // max-height: 40vh;
  word-wrap: break-word;
}

.modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.modal-header {
  padding-bottom: 0;
  border-bottom: 0;
  padding-top: 5px;
  color: $white;
  background-color: lighten($tertiary, 5%);
  background-repeat: no-repeat;
  background-position: 12rem 40%;
  background-size: 150%;
  height: 3rem;

  .fa-times {
    text-shadow: 0 0 0.1rem $black;
    color: $white;
  }

  .close {
    margin: 0 0 0 auto;
    padding: .5rem;
  }
}

.modal-title {
  vertical-align: middle;
  letter-spacing: 1px;
  padding: .5rem 0 .5rem 0;
  // text-shadow: 0 0 0.1rem $black;
}

.modal-body {
  //TODO: This was changed in one of the other styles as the following comments
  // overflow-y: scroll;
  // max-height: 30vh;
  overflow-y: auto;
}

.form-control-sm,
.form-control {
  &.is-invalid {
    outline: 0;
    border-color: lighten($primary, 40%);
    box-shadow: 0 0 0 0.2rem lighten($primary, 40%);
  }
}

.brand-zb-redesign-theme .styled-form,
.brand-zb-redesign-theme .styled-form {
  .half-width {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: 50%;
    }
  }

  input, .ng-select {
    border-radius: $spacing-S;
    border-color: $black;
  }

  select {
    display: block;
    width: 100%;
  }

  label {
    font-family: var(--brand-primary-font, sans-serif);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.03rem;
  }

  .select-input,
  .ng-select-container,
  .form-control-sm, 
  .form-control {
    border-color: $black;
  }

  .form-group {
    .form-label {
      height: 1.6rem;
    }

    @include media-breakpoint-up(md) {
      .form-label {
        text-align: right;
      }

      .form-control {
        width: 100%;
        border: 1px solid black;
      }
    }
  }

  .select-input {
    text-transform: uppercase;
  }

  .errorText {
    font-size: .875rem;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
  }

  .requiredText {
    font-size: $subtext-font-size;
    font-weight: 600;
    color: $black;
    margin-top: $spacing-M;

    @include media-breakpoint-up(md) {
      margin-top: $spacing-L;
    }
  }
}

.brand-zb-redesign-theme .form-control-sm,
.brand-zb-redesign-theme .form-control {
  border-color: $black;
}

/* boostrap spacing is off, keep aware in future updates */

.col-form-label {
  line-height: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: solid transparent;
  border-width: 1px 0;
}

.list-group-item {
  border: none;
}

.input-group {
  .btn {
    margin: 0 .25rem;
  }
}

.input-group.date {
  max-width: 10rem;
}

.text-decoration-linethrough {
  text-decoration: line-through;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

.form-control {
  display: block;
  width: 100%;
  padding: .5rem .75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.25;
  color: #4a4a4a;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 0;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: lighten($tertiary, 25%) !important;
  outline: 0;
  box-shadow: 0 0 0 .2rem transparentize(lighten($tertiary, 25%), .33);
}

.form-control,
.form-control-sm {

  // Overridess Bootstrap 5 fancy select lists.
  &.form-select {
    // All browsers have supported this for the past several years.
    appearance: menulist !important;
    background-image: none !important; //TODO: this line was commented in one of the other styles
  }
}

.input-group.date .input-group-addon {
  cursor: pointer;
}

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}

.input-group-addon {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.25;
  color: #4a4a4a;
  text-align: center;
  background-color: #eceeef;
  border: 1px solid rgba(0, 0, 0, .15);
}

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
}

.nav-tabs {
  border-bottom: 5px solid $primary;
  margin-right: 5px;

  .nav-item {
    background-color: $white;
    border-color: $primary;
    border-width: 1px;
  }
}

.nav-tabs-fill {
  flex-wrap: nowrap;

  @include media-breakpoint-down(xs) {
    flex-wrap: wrap;
    flex-direction: column;
  }

  button {
    flex: 2 1 auto;
  }
}

.border-left-1,
.border-bottom-1 {
  border-color: #686868 !important;
}

.hoverFeedback:hover {
  opacity: 0.5;
}

.emptyResults {
  min-height: 20rem;
}


.table {
  th,
  td {
    border-top: 0;
  }

  .border-1 {
    border: 1px solid !important;
  }

  .border-top-1 {
    border-top: 1px solid !important;
  }

  .border-right-1 {
    border-right: 1px solid !important;
  }

  .border-bottom-1 {
    border-bottom: 1px solid !important;
  }

  .border-left-1 {
    border-left: 1px solid !important;
  }

  .border-2 {
    border: 2px solid !important;
  }

  .border-top-2 {
    border-top: 2px solid !important;
  }

  .border-right-2 {
    border-right: 2px solid !important;
  }

  .border-bottom-2 {
    border-bottom: 2px solid !important;
  }

  .border-left-2 {
    border-left: 2px solid !important;
  }

  &.table-borderless {
    border: 0;
  }
}

.table.table-white {
  background-color: $white;
  color: $gray;
  border: 0.5rem solid $white;

  th,
  td {
    $table-border-color: $gray;
    border-top: 0;
  }
}

.table-striped {
  tbody {
    tr {

      // Overrides bootstrap's greedy, over-specified color style definition.
      .popoverLink {
        color: $primary;
      }
    }
  }
}

.table-striped--reversed {
  .tableBody {
    tr:nth-of-type(2n) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

.table.table-white-with-red-header {
  color: $gray;
  border-collapse: separate;
  border: 0;

  table {
    background-color: $white;
    padding: 0.5rem;
    width: 100%;
  }

  th,
  td {
    border: 0;
  }
}

.table-fixed {
  table-layout: fixed;

  td,
  th {
    word-wrap: break-word;
  }
}

.table.table-white.table-striped tbody tr:nth-of-type(odd),
.table.table-white-with-header.table-striped tbody tr:nth-of-type(odd) {
  background: #f2f2f2;
}

.table td {
  vertical-align: middle;
}

thead th {
  padding-bottom: 0.25rem;
  padding-top: 1.5rem;
  text-transform: uppercase;
}

.tableHeaderSortable {
  cursor: pointer;
  font-weight: normal;
}

.mask-disabled {
  color: $neutralsWarmGreyW900;
}

.btn {
  cursor: pointer;
  border-radius: .25rem;
  word-break: break-word;

  @include media-breakpoint-up(md) {
    word-break: normal;
  }

  &.btn-xs {
    font-size: .9rem;
    padding: 0.1rem 0.4rem;
  }
}

.btn-link {
  color: #b70000;

  &:hover,
  &:active {
    color: #b70000;
  }
}

.btn-link-warning {
  color: shade-color($secondary, 25%);

  &:hover,
  &:active {
    color: shade-color($secondary, 25%);
  }
}

.btn-sm {
  font-size: 1rem;
}

.btn-icon-only {
  padding: 0.25rem;
  color: $primary;
  background-color: transparent;

  &:focus {
    box-shadow: 0 0 0 0 transparent;
  }

  i.fa {
    font-size: 1rem;
  }
}

.btn-tertiary {
  @include button-variant($tertiary, $tertiary);

  // Overrides SASS mixin with CSS variables.
  --bs-btn-bg: var(--brand-tertiary);
  --bs-btn-border-color: var(--brand-tertiary);
  --bs-btn-hover-bg: color-mix(in srgb, var(--brand-tertiary) 95%, #fff);
  --bs-btn-hover-border-color: color-mix(in srgb, var(--brand-tertiary) 95%, #fff);
  --bs-btn-active-bg: color-mix(in srgb, var(--brand-tertiary) 95%, #fff);
  --bs-btn-active-border-color: color-mix(in srgb, var(--brand-tertiary) 90%, #fff);
  --bs-btn-disabled-bg: var(--brand-tertiary);
  --bs-btn-disabled-border-color: var(--brand-tertiary);
}

.btn-outline-tertiary {
  @include button-outline-variant($tertiary, $white, $tertiary, $tertiary);

  &:disabled {
    background-color: $white;
  }
}

.btn-quartenary {
  @include button-variant($quartenary, $quartenary);
}

.btn-outline-quartenary {
  @include button-outline-variant(darken($quartenary, 10), $white, darken($quartenary, 15), darken($quartenary, 15));

  &:disabled {
    background-color: $white;
  }
}

.btn-outline-tertiary,
.btn-outline-secondary,
.btn-outline-tertiary,
.btn-outline-quartenary,
.btn-outline-info,
.btn-outline-success,
.btn-outline-warning,
.btn-outline-danger {
  background: $white;
  border-width: 2px;
}

.btn-sm {
  padding-bottom: 0.15rem !important;
}

.btn-icon-exclamation {
  background: none;
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  margin: 0;
  outline: inherit;
  padding: 0;
}

.buttonIcon {
  color: lighten($tertiary, 10%);
  background: transparent;

  &:hover {
    background: lighten($tertiary, 10%);
    color: #FFFFFF;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem transparentize($tertiary, .75);
  }

  &:disabled {
    border: none;
  }
}

.buttonIconPrimary {
  color: lighten($primary, 10%);
  background: transparent;

  &:hover {
    background: lighten($primary, 10%);
    color: #FFFFFF;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem transparentize($primary, .75);
  }
}

.buttonIconWhitePrimary {
  color: $white;
  background: transparent;
  vertical-align: baseline;

  &:hover {
    background: $white;
    color: $primary;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem transparentize($primary, .75);
  }
}

.buttonIconCircleTeal {
  @include button-variant($teal, $white);

  border-radius: 1.6rem;
  width: 1.6rem;
  height: 1.6rem;
  font-size: .8rem !important;
}

/* These are marked important because jquery blockUI injects inline styles to the blocking overlay */

.blockOverlay {
  background-color: $black !important;
  border: 0 !important;
}

.blockElement {
  background-color: transparent !important;
  border: 0 !important;
}

.popoverLink {
  color: $primary;
  position: relative;
  cursor: pointer;
}

.popover {
  border-color: $tertiary;
  width: auto;
  font-size: 1rem;

  i {
    color: $tertiary;
    top: 0px !important;
    right: 0px !important;
    font-size: initial;
  }

  a {
    color: #ee0027;
  }
}

.brand-highlights-theme,
.brand-zb-redesign-theme {
  .popover {
    border-color: $black;
    background-color: $black;
    width: auto;
    font-size: 1rem;

    .arrow {
      border-top-color: $black;
  
      &:before {
        border-top-color: $black;
      }
    }

    .popover-body {
      color: $white;
    }

    &[data-popper-placement='bottom'] {
      :after {
        border-bottom-color: $black;
      }
    }
    
    &[data-popper-placement='top'], &[data-popper-placement='top-start']   {
      :after {
        border-top-color: $black;
      }
    }

    &[data-popper-placement='left']  {
      :after {
        border-left-color: $black;
      }
    }

    &[data-popper-placement='right']  {
      :after {
        border-right-color: $black;
      }
    }
  }
}

.popover-header {
  display: none;
}

.popover-content {
  padding: 2rem 1rem 2rem 1rem;
}

@media print {
  p {
    font-size: 14pt;
  }

  .modal-open .container-fluid {
    display: none;
  }

  .modal-dialog {
    margin: 0;
    padding: 0;
    max-width: 100%;
  }

  .modal-content {
    display: block;
    border: none;
  }
}

#paperIcon {
  border-radius: 4rem;
  background-color: $primary;
  padding: .75rem;
  width: 4rem;
}

#informationIcon {
  border-radius: 3rem;
  padding: .75rem;
  width: 3rem;
}

#gettingStartedCheckList {
  background-color: $lightBlue;
  border-color: $lightBlue;
}

#logo {
  margin: .8rem .8rem .8rem 1.2rem;
  width: 10rem;

  @include media-breakpoint-up(md) {
    margin-left: .8rem;
  }
}

.searchButton {
  background-color: var(--brand-primary);
  border-color: var(--brand-primary);
}

// Quest
.questLink {
  display: inline-block;
}

.scoreCell {
  .popover {
    @include media-breakpoint-up(xl) {
      max-width: 800px;
    }
  }
}

// ECE Components (Modal, Custom Card)
.transparent-bg .modal-content {
  background: transparent;
  overflow: visible;
  border: unset;
}
.brand-zb-redesign-theme .modal-content,
.brand-highlights-theme .modal-content {
  border: none;
}

.brand-highlights-theme,
.brand-zb-redesign-theme {
  .modal-content {
    border-radius: 8px;
  }
}

.modal-open {
  .modal {
    overflow: visible;
  }
}

.modal-content {
  h1 {
    font-size: 1.5rem
  }

  h2 {
    font-size: 1.4rem
  }

  h3 {
    font-size: 1.3rem
  }

  h4 {
    font-size: 1.2rem
  }

  h5 {
    font-size: 1.1rem;
  }

  h6 {
    font-size: 1rem;
  }

  font-size: 1rem;

  border-color: lighten($tertiary, 7.5%);
  border-radius: .25rem;
}

.brand-zb-redesign-theme,
.brand-highlights-theme {
  .modal-content .modal-title {
    font-family: var(--brand-primary-font, sans-serif);
    font-style: normal;
    font-weight: 700;
    line-height: 83.333%;
    letter-spacing: 0.72px;
    text-transform: uppercase;
  }
}

.full-screen-modal .modal-dialog {
  max-width: 80vw;
  max-height: 80vh;
  margin-top: 2rem;
}

.row {
  margin-right: 0px !important;
}

/** Override ng-select styling **/
.ng-select {
  &.form-control {
    padding: 0;
  }

  &.ng-select-opened {
    > .ng-select-container {
      background: $ng-select-bg;
      border-color: $ng-select-border;

      .ng-arrow {
        top: -2px;
        border-color: transparent transparent $ng-select-border;
        border-width: 0 5px 5px;

        &:hover {
          border-color: transparent transparent darken($ng-select-border, 7.5%); // 7.5% pulled from bs button mixin
        }
      }
    }

    &.ng-select-bottom {
      > .ng-select-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.ng-select-top {
      > .ng-select-container {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }

  &.ng-select-focused {
    &:focus {
      @extend .form-control;
    }
  }

  &.ng-select-disabled {
    > .ng-select-container {
      background-color: $ng-select-disabled-text;
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-select-container {
    border-radius: inherit;
    align-items: center;

    .ng-value-container {
      align-items: center;

      .ng-placeholder {
        &::placeholder {
          @extend .form-control;
        }
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: $input-height-inner;

      .ng-value-container {
        padding-left: $input-padding-x;
        padding-right: $input-padding-x + 2.5rem;

        @include rtl {
          padding-right: $input-padding-x;
          padding-left: $input-padding-x + 2.5rem;
        }

        .ng-input {
          width: auto;
          left: $input-padding-x;
          right: $input-padding-x + 2.5rem;

          @include rtl {
            right: $input-padding-x;
            left: $input-padding-x + 2.5rem;
          }
        }
      }
    }

    &.form-control-sm .ng-select-container {
      height: $input-height-inner;

      .ng-value-container {
        padding-left: $input-padding-x-sm;
        padding-right: $input-padding-x-sm + 2.5rem;

        @include rtl {
          padding-right: $input-padding-x-sm;
          padding-left: $input-padding-x-sm + 2.5rem;
        }

        .ng-input {
          width: auto;
          left: $input-padding-x-sm;
          right: $input-padding-x-sm + 2.5rem;

          @include rtl {
            right: $input-padding-x-sm;
            left: $input-padding-x-sm + 2.5rem;
          }
        }
      }
    }

    &.form-control-lg .ng-select-container {
      height: $input-height-inner;

      .ng-value-container {
        padding-left: $input-padding-x-lg;
        padding-right: $input-padding-x-lg + 2.5rem;

        @include rtl {
          padding-right: $input-padding-x-lg;
          padding-left: $input-padding-x-lg + 2.5rem;
        }

        .ng-input {
          width: auto;
          left: $input-padding-x-lg;
          right: $input-padding-x-lg + 2.5rem;

          @include rtl {
            right: $input-padding-x-lg;
            left: $input-padding-x-lg + 2.5rem;
          }
        }
      }
    }
  }
}

ngb-datepicker {
  select.form-select {
    background-image: none !important;
  }

  .btn-light {

    &:active,
    &.active {
      background-color: #dae0e5;
      border-color: #d3d9df;
    }
  }
}

.pagination-wrapper-end {
  ngb-pagination {
    .pagination {
      justify-content: flex-end;
    }
  }
}

ngb-pagination {
  .pagination {
    justify-content: center;
  }

  .page-item {
    .page-link {
      border: none;
    }
    &.disabled {
      .page-link {
        background-color: $white;
      }
    }
  }
}

.brand-highlights-theme, .brand-zb-redesign-theme {
  ngb-pagination {
    .pagination {
      gap: 8px;
      margin: 1rem 0 0 0;
    }

    .page-item {
      line-height: 21px;
      height: 24px;

      .page-link {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        color: $black;
        border: none;
        border-radius: 3px;
        font-family: var(--brand-primary-font);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.632px;
        text-transform: uppercase;
        background-color: transparent;

        span {
          color: $black;
          font-size: 1.5rem;
        }

        &:hover {
          color: $blue;
          background: $faintBlue;
        }
      }

      &.active .page-link {
        color: $white;
        background-color: var(--brand-primary);
        font-weight: 600;

        &:hover {
          background-color: $primaryFade;
          border: 2px solid $primaryFade;
          transition: none;
        }
      }

      // First and last child updates below are replacing the default pagination arrows for ngb-pagination with custom icons
      // Custom icons are added through CSS's content property for right & left arrows. There is an enabled and disabled version of each.
      // https://ng-bootstrap.github.io/releases/14.x/#/components/pagination/overview#customization
      &:first-child, &:last-child {
        display: flex;
        justify-content: center;
        align-items: center;

        .page-link {
          span {
            display: none;
          }

          &:before {
            padding-top: 2px;
          }
        }
      }

      &:first-child {
        .page-link {
          &:before {
            content: url('./assets/highlights/svgs/arrow-chevron-left-black.svg');
          }
        }

        &.disabled {
          .page-link {
            &:before {
              content: url('./assets/highlights/svgs/arrow-chevron-left-grey.svg');
            }
          }
        }
      }

      &:last-child {
        .page-link {
          &:before {
            content: url('./assets/highlights/svgs/arrow-chevron-right-black.svg');
          }
        }

        &.disabled {
          .page-link {
            &:before {
              content: url('./assets/highlights/svgs/arrow-chevron-right-grey.svg');
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {

  .fullContentContainer,
  .primaryContainer,
  .sideMenu {
    min-height: calc(92vh - 5rem);
  }
}

@include media-breakpoint-up(md) {

  .fullContentContainer,
  .primaryContainer,
  .sideMenu {
    min-height: calc(97vh - 10rem);
  }
}

@include media-breakpoint-up(lg) {

  .fullContentContainer,
  .primaryContainer,
  .sideMenu {
    min-height: calc(97vh - 10.2rem);
  }
}

@include media-breakpoint-up(xl) {

  .fullContentContainer,
  .primaryContainer,
  .sideMenu {
    min-height: calc(100vh - 11.2rem);

    .accordion-header {
      .sideMenuProductLine {
        padding-left: 0;
      }
    }
  }
}


// Unused???
.stdMargin {
  margin: 1.25rem 1.25rem 0;
}

.classGradeSelect {
  font-size: 1rem;
  width: 4rem;
  height: 1.7rem;
}

.scoreCellFreeWrite {
  .popover {
    width: 100%;
  }
}

.spinner-size {
  font-size: 1.3rem !important;
}

.progressBarBody {
  .progress-bar {
    background-color: lighten($tertiary, 7.5%);
  }
}

.bgPrimary, .bg-primary {
  background-color: var(--brand-primary) !important;
  color: $white;
}

.brand-highlights-theme .bg-login {
  background: var(--brand-primary-login-background-image) 0 0 repeat;
}

.brand-zb-redesign-theme .bg-login {
  background: var(--brand-primary-login-background-image) no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-tertiary {
  background-color: var(--brand-tertiary) !important;
}

.bg-image {
  padding: 10px;
  background-image: var(--brand-primary-background-image);
  background-repeat: no-repeat;
  background-size: 100%;

  &.faded {
    background-image: var(--brand-primary-background-image-faded);
  }

  @include media-breakpoint-up(md) {
    min-height: 89vh;
  }

  @include media-breakpoint-up(lg) {
    min-height: 82vh;
  }

  @include media-breakpoint-up(xl) {
    min-height: 78vh;
  }
}

// ZB Portal Redesign's primary background image has its design on the bottom
// Position the image so the bottom is always attached to the bottom of the container, then cover that whole container with the image
// No padding is needed
.brand-zb-redesign-theme .bg-image {
  background-position: bottom;
  background-size: cover;
  padding: 0;
}

.disabled-input {
  background: $neutralsWarmGreyW50;
  border: 1px solid $neutralsWarmGreyW900;
  color: $neutralsWarmGreyW900;

  &:focus {
    background: $neutralsWarmGreyW50;
    box-shadow: initial;
    border: 1px solid $neutralsWarmGreyW900 !important;
    color: $neutralsWarmGreyW900;
  }

  &:hover {
    cursor: default;
  }
}

// Modern Input Styles
label.zbp-form-label {
  color: $black;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;

  &.is-invalid {
    color: $primary;
  }
}

// Global style as actively drug dock card is appended to body of DOM
.dock-card-drag-active {
  pointer-events: none;
  background: $white;
  z-index: 1000; // Matches bootstrap's dropdown menu index
  position: fixed;
}

.brand-highlights-theme, .brand-zb-redesign-theme {
  input[type='checkbox'] {
    border: 1px solid $neutralsWarmGreyW900;

    &:checked {
      accent-color: var(--brand-primary);
      border-color: var(--brand-primary);
    }

    &:hover {
      cursor: pointer;
    }
  }


  .modal-container {
    padding: $spacing-L $spacing-M;

    @include media-breakpoint-only(md) {
      padding: 24px;
    }

    .modal-header {
      height: 100%;
      padding: 0;
      font-family: var(--brand-primary-font);
      color: $black;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.72px;
      background-color: transparent;
    }

    .modal-body {
      font-family: var(--brand-primary-font);
      color: $black;
      overflow-y: auto;
      word-wrap: break-word;
      margin: 3% 0;
      padding: 0;

      p {
        font-size: 1rem;
      }

      @include media-breakpoint-only(md) {
        margin: 16px 0;
      }
    }

    .modal-footer {
      font-family: var(--brand-primary-font);
      overflow-y: auto;
      word-wrap: break-word;
      border-top: none;
      background-color: transparent;
      padding: 0;
      gap: $spacing-S-M;

      @include media-breakpoint-up(lg) {
        gap: $spacing-L;
      }

      zbp-button-component {
        margin: 0;
      }
    }
  }

  select {
    border-radius: 8px;
    color: $black;
    font-family: var(--brand-primary-font);
    font-size: 0.688rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: 4px;
    appearance: menulist;
    background-image: none;
    padding: 8px;

    @include media-breakpoint-up(md) {
      font-size: 0.875rem;
    }

    option {
      font-weight: 400;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.brand-highlights-theme, .brand-zb-redesign-theme {
  .btn:disabled, .button-disabled {
    background-color: $neutralsWarmGreyW100;
    color: $neutralsWarmGreyW900;
    opacity: 1;
  }
}

.brand-highlights-theme, .brand-zb-redesign-theme {
  .toast-container.toast-bottom-center {
    // temp variable since padding value should match in multiple places below
    $temp-desired-padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: $spacing-S;
    font-size: 1rem;

    padding: $temp-desired-padding;
    @include media-breakpoint-up(md) {
      padding: $temp-desired-padding 10vw;
    }
    @include media-breakpoint-up(lg) {
      padding: $temp-desired-padding 15vw;
    }
    @include media-breakpoint-up(xl) {
      padding: $temp-desired-padding 20vw;
    }

    @media print {
      // toasts should not show up when printing
      display: none !important;
    }

    .ngx-toastr {
      // temp variable since background size should match in multiple places below
      $temp-background-size: 2rem;
      display: flex;
      flex-direction: column;
      padding: $temp-desired-padding;
      padding-left: calc($temp-desired-padding + $temp-background-size + $temp-desired-padding);
      justify-content: space-between;
      align-items: flex-start;
      gap: $spacing-XS;
      border-radius: $corner-M;
      background-color: $neutralsCoolGreyG900;
      background-position: $temp-desired-padding center;
      background-size: $temp-background-size;
      color: $white;
      font-family: var(--brand-primary-font, sans-serif);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 3.3px;
      width: 100%;

      @include elevation-4();

      .toast-close-button {
        // current toasts styles do not support close buttons
        // but the error handling service always enables the button
        display: none;
      }

      &:hover {
        // same elevation set to override default toastr library hover
        @include elevation-4();
        background-color: lighten($neutralsCoolGreyG900, 10%);
      }

      &.toast-success {
        background-image: url("/assets/highlights/img/check_circle.svg") !important;
      }

      &.toast-error {
        background-image: url("/assets/highlights/img/error_circle.svg") !important;
      }

      &.toast-info {
        background-image: url("/assets/highlights/img/question_circle.svg") !important;
      }

      .toast-title {
        // using em to only have to parent font size when scaling toast
        font-size: 1.25em;
        font-weight: 700;
      }

      .toast-message {
        // using em to only have to parent font size when scaling toast
        font-size: 1em;
        font-weight: 500;
      }
    }
  }
}

select.styled-select {
  display: flex;
  padding: $spacing-S;
  align-items: center;
  justify-content: space-between;
  border-radius: $corner-M;
  border: 1px solid $black;
  background: $white;
  color: $black;
  font-size: 1em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 0.875rem;
  }

  &:hover {
    cursor: pointer;
  }

  option {
    color: $black;
    font-size: 0.875em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

}

// 'styled-table' class can be added to table element along with 'table' class for redesigned table general styling
.styled-table {
  background-color: transparent;
  border-collapse: separate;
  border-spacing: 0;
  border: none;
  margin-bottom: 0;

  th, td {
    padding: 1rem;
    text-align: left;
    max-width: 25vw;
  }

  th, td, .text-wrap {
    text-wrap: wrap;
  }

  thead {
    tr,
    tr th,
    tr th button.sortable-column-header {
      color: $neutralsCoolGreyG900;
      font-family: var(--brand-primary-font);
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: 1.26px;
      text-transform: uppercase;
    }

    tr {
      th {
        background-color: $headerBlue;
        border-top: 1px solid $neutralsWarmGreyW80;
        border-bottom: 1px solid $neutralsWarmGreyW80;

        button.sortable-column-header {
          display: flex;
          flex-direction: row;
          gap: $spacing-XS;
          justify-content: flex-start;
          align-items: center;
          background: none;
          border: none;

          .sort-icon {
            font-style: normal;
            padding: 0;
          }
        }
      }

      // adding the border radius to the head row
      th:first-of-type {
        border-top-left-radius: $corner-L;
        border-left: 1px solid $neutralsWarmGreyW80;
      }

      th:last-of-type {
        border-top-right-radius: $corner-L;
        border-right: 1px solid $neutralsWarmGreyW80;
      }
    }
  }

  tbody {
    border: none;

    td {
      border: none;
      background-color: $white;

      &.table-body-message {
        text-align: center;
      }

      ul.text-list {
        display: inline;
        overflow-wrap: anywhere;
        padding: 0;
        margin: 0;

        li:not(:first-child) {
          margin-top: $spacing-XS;
        }
      }
    }

    td:first-of-type {
      border-left: 1px solid $neutralsWarmGreyW80;
    }

    td:last-of-type {
      border-right: 1px solid $neutralsWarmGreyW80;
    }

    tr {
      color: $neutralsCoolGreyG900;
      font-family: var(--brand-primary-font);
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1.26px;

      td {
        border-bottom: 1px solid $neutralsWarmGreyW80;
      }
    }

    // adding the border radius to the bottom row
    tr:last-of-type {
      td {
        border-bottom: 1px solid $neutralsWarmGreyW80;
      }

      td:first-of-type {
        border-bottom-left-radius: $corner-L;
        border-left: 1px solid $neutralsWarmGreyW80;
      }

      td:last-of-type {
        border-bottom-right-radius: $corner-L;
        border-right: 1px solid $neutralsWarmGreyW80;
      }
    }

    td.actions-cell {
      font-size: 1.5rem;

      .actions-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: $spacing-M;
      }
    }
  }

  tfoot {

    tr {

      td {
        padding: 0;
        padding-top: $spacing-L;
        border: none;

        ngb-pagination {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-start;

          .pagination {
            margin: 0;
          }
        }
      }
    }
  }

  i {
    font-family: 'Font Awesome 6 Free' !important;
  }

  .fa-sort {
    padding: 0 5px;

    &:hover {
      cursor: pointer;
    }
  }

  &.transparent {
    thead {
      tr,
      tr th,
      tr th button.sortable-column-header {
        color: $neutralsCoolGreyG900;
        font-family: var(--brand-primary-font, 'sans-serif');
        font-size: .75rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.26px;
        text-transform: uppercase;
      }
  
      tr {
        th {
          background-color: transparent;
          border-top: none;
          border-bottom: 2px solid $black;
        }
        
        th:first-of-type {
          border-top-left-radius: 0;
          border-left: none;
        }
  
        th:last-of-type {
          border-top-right-radius: 0;
          border-right: none;
        }
      }
    }
  
    tbody {
      td {
        background-color: transparent;
      }
  
      tr {
        td {
          border-bottom: none;
        }
      }
  
      td:first-of-type {
        border-left: none;
      }
  
      td:last-of-type {
        border-right: none;
      }
  
      tr:last-of-type {
        td {
          border-bottom: none;
        }
  
        td:first-of-type {
          border-bottom-left-radius: 0;
          border-left: none;
          background-color: transparent;
        }
  
        td:last-of-type {
          border-bottom-right-radius: 0;
          border-right: none;
          background-color: transparent;
        }
      }
    }
  }
}

.start-date-modal {
  max-height: 90vh;
  overflow: auto;
}

:root {
  --bs-table-color: var(--bs-white-rgb) !important;
}

.table {
  --bs-table-bg: transparent;
}

.table > :not(caption) > * > * {
  color: inherit;
}

.password-reset-flow-form {
  width: 100%;
  max-width: 90%;

  @include media-breakpoint-up(md) {
    max-width: 80%
  }

  @include media-breakpoint-up(lg) {
    max-width: 60%
  }

  @include media-breakpoint-up(xl) {
    max-width: 50%
  }
}

.fixed-position-top-left {
  position: fixed;
  top: 0;
  left: 0;
  align-self: start;
}

.hidden {
  visibility: hidden;
}
