@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

// TODO: once all sass has been converted we shouldn't need the brand vars included here
// it will give an immediate indicator that a variable has been set up incorrectly
@import './zbportal/zb-variables';

// The initial list of variables are common between all brands
// if any of these variables needs to be different between brands they
// should be added to the appropriate brand-variables file.

// TODO: convert ScSS variables to utilize the CSS variable format var(--variable)
// there are some places such as maps or functions/mixins where this might not be
// possible.  These will need to be addressed individually to determine the best
// approach.

// TODO: there are some variables used by bootstrap i.e. ($theme-colors) which can not
// be switched at runtime.  As such any bootstrap component that normally would utilize
// those variables such as $primary will need to have a custom style in styles.scss
// to override and set the properties of that bootstrap component.

/* ==================================================================================== */
/* Spacing / Padding Variables */
$spacing-XS: 4px;
$spacing-S: 8px;
$spacing-S-M: 12px;
$spacing-M: 16px;
$spacing-M-L: 20px;
$spacing-L: 24px;
$spacing-XL: 32px;
$spacing-XXL: 48px;

/* Border Radius Variables */
$corner-S: 4px;
$corner-M: 8px;
$corner-L: 16px;
$corner-XL: 24px;

/* Font Variables (Highlights / New ZB Only) */
$page-header-font-size: 2rem;
$page-sub-header-font-size: 1.25rem;
$line-height-default: 120%;
$subtext-font-size: 0.8rem;
$modal-header-font-size: 1.5rem;

/* Custom Variables */
$primaryFade: #F16D87;
$primaryFade2: rgba(245, 152, 170, 0.67);
$primaryFade3: #F598AA;
$primaryFade4: #fdf5f5; // rgba(245, 152, 170, 0.10) over white
$transparentBgHover: rgba(255, 255, 255, 0.25);
$colorsBgHover: #FDE6EA;
$tertiary: #006979;
$student: #37788D;
$quartenary: #ff5762;
$teal: #85E1FB;
$tealDark: #5DC3D2;
$secondaryTealT500: #007179;
$lightBlue: #eefcff;
$headerBlue: #5BCBC9;
$green: #AAD365;
$validGreen: #00954C;
$line: #D8D8D8;
$secondaryFade: #f5fbff; // rgba(148, 213, 249, 0.10) over white
$green500: #80B230;
$green100: #EAF5D2;

// SuperKids Vars
$skDarkTeal: #306E87;
$skLightTeal: #D1EDFC;
$skLighterTeal: #F3FAFE;
$skTeal: #6BBCDA;
$skDarkerTeal: darken($skDarkTeal, 20%); // resulting code is #15313C
$skOrange: #F0A56D;
$skLightOrange: #E2B985;
$skPaleYellow: #FDF5DF;
$skYellow: #FFFFDF;
$aprilViolet: #5A2F85;

// shared header component header.component.scss
$dark-Student-Link: darken($student, 10%); // resulting color is #295968
$darker-Student-Link: darken($student, 20%); // resulting color is #1A3A44

// shared button component
$neutralsCoolGreyG100: #869199;
$neutralsCoolGreyG200: #79858E;
$neutralsCoolGreyG500: #53626E;
$neutralsCoolGreyG900: #1F3343;

// various components
$neutralsCoolGreyG40: #E2E4E7;
$neutralsCoolGreyG400: #606E7A;
$neutralsWarmGreyW20: #FAFAFB;
$neutralsWarmGreyW50: #DEE1E5;
$neutralsWarmGreyW80: #C7CDD3;
$neutralsWarmGreyW100: #B7BFC7;
$neutralsWarmGreyW200: #AFB8C1;
$neutralsWarmGreyW400: #A0ABB5;
$neutralsWarmGreyW500: #ADB5BD;
$neutralsWarmGreyW600: #8792A0;
$neutralsWarmGreyW900: #798897;
$neutralsWarmGrey900: #515861;
$addIconBackground: #D9D9D9;
$faintBlue: #E0F4FF;
$faintBlue2: #94D5F9;
$brightRedFade: rgba(255, 0, 0, 0.2);
$mineShaftGrey: rgba(33, 33, 33, 1);
$trasparentLightGrey: rgba(0, 0, 0, 0.25);
$ssoBorderGrey: #E2E8F0;
$darkCoolGrey: #1E293B;

//TODO: These variables appear to be unused.  A more thorough investigation
// should be conducted before completely removing them.
// $popover-arrow-outer-width: 40px;
// $green-forest: #36778d;
// $btn-font-weight: 500;
// $alt-background: #dfdfdf;
// $alt-border: 1px solid #c6c6c6;
// $palletesPrimaryP100: #F16D87;
// $colorsHlBlue: #0066B3;
// $colorsHlRoyalBlue: #9989E1;
// $colorsCustomCardColor: #7787c4;
// $zbOfficalPrimaryRed: #E60330;
// $tooltipBackground: #FFFFD4;
// $pwapSeptemberRed: #ED1C24;
// $cardFill: rgba(119, 135, 196, 0.5);
// $addIconBackgroundHover: rgba(121, 136, 151, 0.20);
// $calControlHover: rgba(255, 0, 0, 0.2);

/* ==================================================================================== */
/* Bootstrap */
$icon-font-path: '../node_modules/bootstrap-sass/assets/fonts/bootstrap/';
$gray: #4a4a4a;
$mediumGray: #666;
$lightGray: #dfdfdf;
$darkGray: rgb(51, 51, 51);
$white: #fff;
$black: #000;
$blue: #0080E1;
$navbar-inverse-color: $white;
$navbar-inverse-hover-color: $white;
$font-family-base: museo-sans;
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: $primary;
// Overrides bootstrap button transition variable to be .2s instead of .15s.
$btn-transition: color .2s ease-in-out, background-color .2s ease-in-out, opacity .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out !default;
$popover-arrow-color: darken($tertiary, 7.5%); //result is #004853
$popover-arrow-outer-color: darken($tertiary, 7.5%);
$popover-arrow-width: 20px;
$popover-max-width: 600px;
$popover-inner-padding: 1.25rem;
$nav-tabs-active-link-hover-bg: $primary;
$nav-tabs-active-link-hover-color: $white;
$nav-tabs-border-color: $primary;
$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-border-color: $primary;
$nav-tabs-link-active-bg: $primary;
$nav-link-color: #b70000;
$link-color: #b70000;
$link-hover-color: $primary;
$accordion-button-color: $primary;
$accordion-button-active-color: $white;
$accordion-button-active-bg: $primary;
$accordion-button-focus-button-color: $primary;
$accordion-button-focus-box-shadow: shift-color($primary, 20%); // result is #B31E14
$accordion-button-icon: unset;
$accordion-button-active-icon: unset;
$accordion-body-padding-y: 0px;
$accordion-body-padding-x: 0px;
$accordion-border-width: 0px;
$input-height: 33px;
// This is used by bootstrap to make modal corners rounded
$enable-rounded: false;

/* ==================================================================================== */
/* Custom Map Variables */

$custom-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": #008000,
  "danger": #ff4136,
  "warning": #c08a3e,
  "info": $teal,
  "tertiary": $tertiary,
  "quartenary": $quartenary,
  "student": #37788D,
);

/* Bootstrap maps (i.e. $colors, $theme-colors, and $grays) */
$theme-colors: map-merge($theme-colors, $custom-colors);

$pagination_button-blue: #53B0D1;
$pagination_button-grey: #A1A1A1 ;
// The following are the standard Bootstrap theme-colors mapping variables
// success:             $green !default;
// info:                $teal !default;
// warning:             $orange !default;
// danger:              $red !default;
// inverse:             $gray-dark !default;
// light:               $light !default;
// dark:                $dark !default;

// The following are the standard Bootstrap colors mapping variables
//   "blue":       $blue,
//   "indigo":     $indigo,
//   "purple":     $purple,
//   "pink":       $pink,
//   "red":        $red,
//   "orange":     $orange,
//   "yellow":     $yellow,
//   "green":      $green,
//   "teal":       $teal,
//   "cyan":       $cyan,
//   "white":      $white,
//   "gray":       $gray-600,
//   "gray-dark":  $gray-800
