@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

/*
* These are used in a couple areas of the existing ZB Portal.
* Any new variables used for the redesign should be added to _zb-redesign-variables.scss
*/
$brandName: 'zb';
$brandTest: lime;

/* Bootstrap */
$primary: #EE0027;
$secondary: #FC6100;
$tertiary: #006979; // verify during branding that this is correct for tertiary
$primaryFade: #F16D87;

$primaryFont: museo-sans;
$primaryBackgroundImage: url('/assets/img/weekly-planner.svg');
$primaryBackgroundImageFaded: url('/assets/img/weekly-planner-15-opacity.svg');

/* Custom Variables */
$primaryLoginBackgroundImage: url('../assets/zbportal/img/primary-login-background.jpg');
$primaryLogo: url('../assets/zbportal/img/zb-logo.svg');
$loginFormBackgroundImage: '';
