//TODO: This may be an alternate way of including vars in root scope
// based on the CSS selector rather than copying them in theme service.
// @at-root [ data-theme="#{$brand_Name}-theme"] {
//   --test-primary: #{$primary};
// }

// also add CSS var to theme.service
@at-root .brand-#{$brandName}-theme {
  --brand-test: #{$brandTest};

  --brand-primary: #{$primary};
  --brand-secondary: #{$secondary};
  --brand-tertiary: #{$tertiary};
  --brand-primary-fade: #{$primaryFade};

  --brand-primary-font: #{$primaryFont};

  --brand-primary-logo: #{$primaryLogo};

  --brand-primary-background-image: #{$primaryBackgroundImage};
  --brand-primary-background-image-faded: #{$primaryBackgroundImageFaded};
  --brand-primary-login-background-image: #{$primaryLoginBackgroundImage};
  --brand-login-form-background-image: #{$loginFormBackgroundImage};
}
