@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

$brandName: 'highlights';
$brandTest: orange;

/* Bootstrap */
$primary: #E02619;
$secondary: #0066B3;
$tertiary: #0066B3;
$primaryFade: #F16D87;

$primaryFont: Montserrat, system-ui, arial, sans-serif;
$primaryBackgroundImage: url('/assets/img/weekly-planner.svg');
$primaryBackgroundImageFaded: url('/assets/img/weekly-planner-15-opacity.svg');

/* Custom Variables */
$primaryLoginBackgroundImage: url('../assets/img/highlights-login-bg.png');
$loginFormBackgroundImage: url('../assets/img/highlights-login-bg.png');
$primaryLogo: url('../assets/img/highlights-logo.svg');